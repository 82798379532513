import React, { useContext, type ReactNode } from 'react';
import ReactModal from 'react-modal';
import { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import THEMES from '../../styles/themes/app';
import {
  SubheaderWrapper,
  ChildrenWrapper,
  getOverlayStyles,
  FlexWrapper,
} from './styles';
import Footer from './Footer/Footer';
import DetectClickOutside from '../Common/DetectClickOutside';
import type {
  ButtonActionTypes,
  ButtonLabelTypes,
  ButtonPropTypes,
} from './types';
import Header from './Header/Header';

export enum FlyoutDirections {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface FlyoutProps {
  buttonActions?: ButtonActionTypes;
  buttonLabels?: ButtonLabelTypes;
  buttonProps?: ButtonPropTypes;
  children: ReactNode;
  customStyles?: {
    modal?: () => string;
    subheader?: () => string;
    content?: () => string;
  };
  direction?: FlyoutDirections;
  disableFooter?: boolean;
  disableHeader?: boolean;
  disableOverlay?: boolean;
  error?: string;
  isOpen: boolean;
  onClickOutside?: () => void;
  onRequestClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  subheader?: ReactNode;
  subtitle?: string;
  title?: string;
  titleIcon?: string;
}

const Flyout = ({
  buttonActions = {},
  buttonLabels = {},
  buttonProps = {},
  children,
  customStyles = {},
  direction = FlyoutDirections.RIGHT,
  disableFooter = false,
  disableHeader = false,
  disableOverlay = false,
  error = '',
  isOpen,
  onClickOutside = () => {},
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  subheader = null,
  subtitle = '',
  title = '',
  titleIcon = '',
}: FlyoutProps) => {
  const styledTheme = useContext(ThemeContext);

  const animation = useSpring({
    position: 'absolute',
    top: 0,
    [direction]: isOpen ? 0 : -300,
    backgroundColor: `${THEMES.BACKGROUND_PRIMARY(styledTheme)}`,
    width: '438px',
    height: '100vh',
    boxShadow: '-4px 0px 4px rgba(0, 0, 0, 0.25)',
  });

  return (
    <ReactModal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ overlay: getOverlayStyles(disableOverlay) }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      // @ts-expect-error css is an expected prop
      css={customStyles?.modal?.()}
    >
      <DetectClickOutside onClickOutside={onClickOutside}>
        <animated.div style={animation}>
          <FlexWrapper>
            {!disableHeader && (
              <Header
                title={title}
                subtitle={subtitle}
                onRequestClose={onRequestClose}
                titleIcon={titleIcon}
                titleButton={buttonLabels?.title}
                titleButtonAction={buttonActions.title}
                titleButtonProps={buttonProps.title}
              />
            )}
            {subheader && (
              <SubheaderWrapper customStyle={customStyles.subheader?.() || ''}>
                {subheader}
              </SubheaderWrapper>
            )}
            <ChildrenWrapper customStyle={customStyles.content?.() || ''}>
              {children}
            </ChildrenWrapper>
            {!disableFooter && buttonLabels && (
              <Footer
                buttonLabels={buttonLabels}
                buttonActions={buttonActions}
                buttonProps={buttonProps}
                error={error}
                title={title}
              />
            )}
          </FlexWrapper>
        </animated.div>
      </DetectClickOutside>
    </ReactModal>
  );
};

export default Flyout;
