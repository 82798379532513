import type { CustomerContact } from '../../utils/helpers/types';

export enum RATING_TYPES {
  DISSATISFIED = 'DISSATISFIED',
  SATISFIED = 'SATISFIED',
  NEUTRAL = 'NEUTRAL',
}

export type Response = {
  question: string;
  response: string | RATING_TYPES;
  type: string;
  __typename: 'Response';
};

export type Feedback = {
  completedAt: Date | string;
  contact: CustomerContact;
  id: string;
  responses: Response[];
  sentAt: Date | string;
  __typename: 'Feedback';
};
