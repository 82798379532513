import React from 'react';
import i18n from 'i18n-js';
import { useHistory } from 'react-router-dom';
import {
  contactName,
  formatPhoneNumber,
  parseCorrectAccountInfo,
} from 'client-lib/src/lib/utils/helpers';
import { EntityCard, Text, IconButton } from '../../elements';
import ChipLabel from '../../elements/Chip/ChipLabel';
import {
  FeedbackDetailsContainer,
  FlexData,
  TextContainer,
  CloseButtonContainer,
  CardHeaderContainer,
} from './styles';
import LABEL_THEMES from '../../styles/themes/library/label';
import {
  CONTACT_LABEL_COLORS,
  getRatingChipLabel,
  getResponseMessage,
  getResponseRating,
} from './shared';
import { RATING_TYPES, type Feedback } from './types';

interface FeedbackDetailsCardProps {
  selected: Feedback;
  dataTestId: string;
}

const getTextStyles = () => `
  word-wrap: break-word;
  width: 100%;
  max-height: 320px;
  overflow-y: auto;
`;

const getTextSize = () => `
  font-size: 14px;
`;

const getEntityCardStyle = () => `
  background-color: transparent;
  border:0;
`;

const FeedbackDetailsCard = ({
  selected,
  dataTestId,
}: FeedbackDetailsCardProps) => {
  const history = useHistory();
  const subtext = parseCorrectAccountInfo(selected?.contact);
  const rating = getResponseRating(selected.responses);
  const message = getResponseMessage(selected.responses);
  return (
    <FeedbackDetailsContainer data-testId={dataTestId}>
      <CardHeaderContainer>
        <EntityCard
          customContainerStyle={getEntityCardStyle}
          maintext={
            contactName(selected?.contact) ||
            formatPhoneNumber(selected?.contact?.phoneNumber)
          }
          subtext={subtext}
          avatarChildren={contactName(selected?.contact)}
        />
        <CloseButtonContainer>
          <IconButton
            onClick={() => {
              history.push('/feedback');
            }}
            noOutline
            size="lg"
          >
            <i className="ri-close-line" />
          </IconButton>
        </CloseButtonContainer>
      </CardHeaderContainer>
      <FlexData>
        <ChipLabel
          dataTestId="feedback-chip-label"
          backgroundColor={CONTACT_LABEL_COLORS[rating as RATING_TYPES]}
          textColor={LABEL_THEMES.LABEL_TEXT}
          size="sm"
        >
          {getRatingChipLabel(rating)}
        </ChipLabel>

        {selected?.completedAt ? (
          <Text customStyle={getTextSize} contrast="low">
            {i18n.strftime(new Date(selected?.completedAt), '%l:%M %p')}
          </Text>
        ) : null}
      </FlexData>
      <TextContainer>
        <FlexData>
          <Text customStyle={getTextStyles}>{message}</Text>
        </FlexData>
      </TextContainer>
    </FeedbackDetailsContainer>
  );
};

export default FeedbackDetailsCard;
