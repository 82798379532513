import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot, type LexicalEditor } from 'lexical';

const convertLexicalToHtmlAndText = (editor: LexicalEditor) => {
  let html = '';
  let text = '';

  editor.update(() => {
    html = $generateHtmlFromNodes(editor, null);
    text = $getRoot().getTextContent();
  });

  return {
    html,
    text,
  };
};

export default convertLexicalToHtmlAndText;
