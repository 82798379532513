import React from 'react';
import { formatDataTestName } from 'client-lib/src/lib/utils/helpers';
import { IconButton, Heading2, Text, Avatar } from '../../../elements';
import {
  AvatarContainer,
  Container,
  InnerContainer,
  TitleWrapper,
  getSubtitleTextStyles,
  getHeaderTextStyles,
  TilteButtonContainer,
  FlexBox,
} from './styles';

export interface HeaderProps {
  onRequestClose: () => void;
  subtitle?: string;
  title?: string;
  titleButton?: string;
  titleButtonAction?: () => void;
  titleButtonProps?: { [key: string]: string | boolean };
  titleIcon?: string;
}

const Header = ({
  onRequestClose,
  subtitle = '',
  title = '',
  titleButton = '',
  titleButtonAction = () => {},
  titleButtonProps = {},
  titleIcon = '',
}: HeaderProps) => {
  return (
    <Container>
      <InnerContainer>
        <TitleWrapper>
          <FlexBox>
            {titleIcon && (
              <AvatarContainer>
                <Avatar
                  role="img"
                  icon={titleIcon}
                  size={subtitle ? 'sm' : 'lg'}
                />
              </AvatarContainer>
            )}
            <Heading2 customStyle={getHeaderTextStyles}>{title}</Heading2>
            <TilteButtonContainer>
              {titleButton && (
                <IconButton
                  size={titleButtonProps?.size}
                  onClick={() => titleButtonAction()}
                  contrast={titleButtonProps?.contrast}
                  className={titleButton}
                  disabled={titleButtonProps?.disabled}
                />
              )}
            </TilteButtonContainer>
          </FlexBox>
          <IconButton
            size="lg"
            onClick={onRequestClose}
            dataTestId={`close-${formatDataTestName(title)}-icon-button`}
            className="ri-close-line"
          />
        </TitleWrapper>
        <Text customStyle={getSubtitleTextStyles}>{subtitle}</Text>
      </InnerContainer>
    </Container>
  );
};

export default Header;
