import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';
import { TEMPLATES_QUERY } from 'client-lib';
import useLazyPaginated from 'client-lib/src/lib/api/query/useLazyPaginated';
import { AsyncSelectPaginate } from '../../elements';

const TemplateAsyncSelect = ({
  value,
  setValue,
  error,
  queryVars,
  onClear,
  ...props
}) => {
  const client = useApolloClient();
  const [queryError, setQueryError] = useState('');

  const { triggerQuery, handleFetchMore, pageInfo } = useLazyPaginated({
    client,
    query: TEMPLATES_QUERY,
    key: 'templates',
    resultsNumber: 25,
    queryVariables: {
      filter: value,
      ...queryVars,
    },
  });

  const formatTemplatesToInputOptions = (data) => {
    return data?.templates?.edges.map((edge) => ({
      label: edge.node.name,
      value: edge.node.id,
      message: edge.node.message,
      attachments: edge.node.attachments,
      // used for template preview inside CreateOption in AsyncSelectPaginage component
      // I chose to include this to make it reusable since we don't know if "message"
      // will alaways be a part of the option object for other AsyncSelectPaginate use cases.
      rightSideOptionContent: edge.node.message,
    }));
  };

  const initialQuery = (inputVal) => {
    return triggerQuery({
      variables: {
        filter: inputVal,
        first: 25,
        ...queryVars,
      },
    });
  };

  const fetchMore = () => {
    return handleFetchMore();
  };

  const loadOptions = async (inputVal, prevOptions, additional) => {
    let response;

    if (prevOptions?.length > 0 && additional.prevInputVal === inputVal) {
      response = await fetchMore(inputVal, prevOptions);
    } else {
      response = await initialQuery(inputVal);
    }

    const { data, error } = response;

    const hasMore = data?.templates?.pageInfo?.hasNextPage;

    if (error) {
      setQueryError('error');
      return { options: [], hasMore: false };
    }

    const formattedTemplates = formatTemplatesToInputOptions(data);

    return {
      options: formattedTemplates,
      hasMore,
      additional: { prevInputVal: inputVal },
    };
  };

  return (
    <AsyncSelectPaginate
      value={value}
      onChange={(selectedOption) => setValue(selectedOption)}
      onClear={onClear}
      loadOptions={loadOptions}
      hasMore={pageInfo.hasNextPage}
      error={queryError || error}
      dataTestId="group-async-select"
      {...props}
    />
  );
};

TemplateAsyncSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setValue: PropTypes.func.isRequired,
  onClear: PropTypes.func,
  error: PropTypes.string,
  queryVars: PropTypes.object,
};

TemplateAsyncSelect.defaultProps = {
  value: null,
  error: '',
  queryVars: undefined,
  onClear: () => {},
};

export default TemplateAsyncSelect;
