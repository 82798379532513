import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from 'styled-theming';
import THEMES from '../../styles/themes/library/chip';
import DEFAULT_THEMES from '../../styles/themes/app';
import IconButton from '../Button/IconButton';
import { EmphasisText } from '../Text/Text';

const SMALL_CHIP_FONTSIZE = theme('fontSize', {
  default: '11px',
  large: '15px',
});

const DELETE_ICON_SIZE_MD = theme('fontSize', {
  default: 24,
  large: 28,
});

const DELETE_ICON_SIZE_SM = theme('fontSize', {
  default: 20,
  large: 24,
});

const sizeStyles = (props) => ({
  sm: {
    padding: props.extraPadding ? '2px 4px 2px 8px' : '2px 8px',
  },
  md: {
    padding: props.extraPadding ? '4px 4px 4px 8px' : '4px 8px',
  },
});

const DeleteIcon = styled.i``;

const ChipEncapsulator = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor(props)
      : THEMES.BACKGROUND(props)};
  padding: ${(props) => sizeStyles(props)[props.size].padding};
  border-radius: 4px;
`;

const calcPaddingRight = (props) => {
  if (props.paddingRight) {
    return props.size === 'sm' ? 4 : 8;
  }
  return 0;
};

const TextWrap = styled.div`
  margin-right: ${(props) => calcPaddingRight(props)}px;
  display: flex;
  align-items: center;
  min-height: ${(props) =>
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props)}px;
  gap: ${(props) => (props.icon ? '4px' : null)};
  && i {
    color: ${(props) =>
      props?.textColor
        ? props.textColor(props)
        : DEFAULT_THEMES.FOREGROUND_MED(props)};
  }
`;

const calcMaxWidth = (props) => {
  const deleteIconWidth =
    props.size === 'sm'
      ? DELETE_ICON_SIZE_SM(props)
      : DELETE_ICON_SIZE_MD(props);
  // Return sum of padingRight for text element plus paddingRight and paddingLeft (always 8) of Chip element
  const padding = calcPaddingRight(props) + (props.paddingRight ? 4 : 8) + 8;
  return props.maxWidth - (props.paddingRight ? deleteIconWidth : 0) - padding;
};

const textCustomStyle = (props) => `
  font-size: ${props.size === 'sm' ? SMALL_CHIP_FONTSIZE(props) : 'initial'};
  font-weight: 600;
  color: ${props?.textColor ? props?.textColor(props) : 'auto'} !important;

  ${
    props?.maxWidth &&
    `
    overflow: hidden;
    max-width: ${calcMaxWidth(props)}px;
    white-space: nowrap;
    text-overflow: ellipsis;
    `
  }
`;

const ChipLabel = ({
  children,
  onDelete,
  size,
  icon,
  maxWidth,
  highlight,
  backgroundColor,
  textColor,
}) => {
  const displayX = !!onDelete;

  return (
    <ChipEncapsulator
      size={size}
      extraPadding={!!onDelete}
      highlight={highlight}
      backgroundColor={backgroundColor}
    >
      <TextWrap
        icon={icon}
        paddingRight={displayX}
        size={size}
        highlight={highlight}
        textColor={textColor}
      >
        {icon || null}
        <EmphasisText
          contrast="med"
          paddingRight={displayX}
          size={size}
          maxWidth={maxWidth}
          highlight={highlight}
          customStyle={textCustomStyle}
          textColor={textColor}
        >
          {children}
        </EmphasisText>
      </TextWrap>
      {displayX && (
        <IconButton
          size={size}
          onClick={onDelete}
          highlight={highlight}
          textColor={textColor}
          customStyle={(props) => `color: ${props.textColor(props)}; &:hover{
            color: ${props.textColor(props)};
          }`}
          noOutline
        >
          <DeleteIcon className="ri-close-fill" size={size} />
        </IconButton>
      )}
    </ChipEncapsulator>
  );
};

ChipLabel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
  ]).isRequired,
  onDelete: PropTypes.func,
  size: PropTypes.string,
  icon: PropTypes.element,
  maxWidth: PropTypes.number,
  highlight: PropTypes.bool,
  backgroundColor: PropTypes.func,
  textColor: PropTypes.func,
  dataTestId: PropTypes.string,
};

ChipLabel.defaultProps = {
  onDelete: null,
  size: 'md',
  icon: null,
  maxWidth: 250,
  highlight: false,
  backgroundColor: () => {},
  textColor: () => {},
  dataTestId: '',
};

export default ChipLabel;
