import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { AVAILABLE_PERMISSIONS, useCanUserPerformActions } from 'client-lib';
import { useApolloClient } from '@apollo/client';
import {
  setUserAccountPolicies,
  setUserAccountPoliciesQueried,
} from '../../actions/session';

const webSignInActions = [
  AVAILABLE_PERMISSIONS.EDIT_ANNOUNCEMENT, // PRKP-5288 Leave this permission in the bulk grab for now; Needed for signin
  AVAILABLE_PERMISSIONS.LIST_CUSTOMERS, // Leave this permission for now - widespread throughout client lib and needed on startup anyhow.
  AVAILABLE_PERMISSIONS.VIEW_DASHBOARD, // PRKP-5288 Leave this permission in the bulk grab for now; Needed for signin
  AVAILABLE_PERMISSIONS.VIEW_THREAD_REPORT, // PRKP-5288 Leave this permission in the bulk grab for now; Needed for signin
  AVAILABLE_PERMISSIONS.VIEW_FEEDBACK, // PRKP-8226
];

const useGetAllAccountPolicies = () => {
  const dispatch = useDispatch();
  const client = useApolloClient();

  const userId = useSelector((state) => state.session.currentUser?.userId);
  const currentUserAccountPolicies = useSelector(
    (state) => state.session.currentUser?.accountPolicies,
    (curr, prev) => curr?.length === prev?.length
  );

  const [loadingAccountPolicies, setLoadingAccountPolicies] = useState(false);
  const [getAllAccountPoliciesFinished, setGetAllAccountPoliciesFinished] =
    useState(false);

  // Need to update useCanUserPerformAction to return a lazyload
  // Needed so the hook can be called, but optionally execute
  //  the graph query after userId is found.
  const [canUserPerformActions, { loading, error, data }] =
    useCanUserPerformActions({
      userId,
      actions: webSignInActions,
      client,
    });

  useEffect(() => {
    if (!userId || getAllAccountPoliciesFinished) {
      return;
    }

    // Explicity _not_ checking for existing permissions.
    // The initial pull must render once, and will not be called again anyway
    // No reason to be tracking current policies, as it causes re-renders in this hook.

    canUserPerformActions();
  }, [userId, getAllAccountPoliciesFinished]);

  useEffect(() => {
    // Error first to ensure we emit messages
    if (error) {
      // TODO: What do we do with our hook error handling??
      console.error('useGetAllAccountPolicies: error: ', error);
      return;
    }
    if (loading || !data) return;
    if (getAllAccountPoliciesFinished) return;

    // API returns single account/actions.  Redux is using an array,
    // likely to allow for easy mashing with groups.  Mutate to match single
    // array element for redux before dispatch.
    const accountPolicy = data.canUserPerformActions;
    // Destructuring to remove 'error' field that comes back
    const policies = [
      {
        accountId: accountPolicy.accountId,
        actions: accountPolicy.actions,
      },
    ];

    dispatch(setUserAccountPolicies(policies));
    dispatch(setUserAccountPoliciesQueried(webSignInActions));
    setLoadingAccountPolicies(true);
  }, [loading, error, data, getAllAccountPoliciesFinished]);

  useEffect(() => {
    if (loadingAccountPolicies && currentUserAccountPolicies.length > 0) {
      setLoadingAccountPolicies(false);
      setGetAllAccountPoliciesFinished(true);
    }
  }, [loadingAccountPolicies, currentUserAccountPolicies]);

  return getAllAccountPoliciesFinished;
};

export default useGetAllAccountPolicies;
