import styled from 'styled-components';
import THEMES from '../../styles/themes/app';

export const getLightText = () => `font-weight: 400;`;

export const FlexData = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
`;

export const FeedbackDetailsContainer = styled.div`
  flex: 4;
  background-color: ${THEMES.BACKGROUND_QUATERNARY};
  border-radius: 8px;
  margin-top: 40px;
  height: 553px;
  max-width: 438px;
`;

export const TextContainer = styled.div`
  padding-top: 16px;
  width: 100%;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
  padding-right: 12px;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Wrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;
`;

export const CardContainer = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24;
`;

export const FeedbackOffContainer = () => `
  height: calc(100vh - 152px);
  margin-top: 16px;
`;

export const HeaderStyle = () => `
  width: 500px;
  text-align: center;
  padding-bottom: 24px;
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
`;
