import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  type RefObject,
} from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
  $isTextNode,
  type LexicalEditor,
} from 'lexical';
import { $wrapNodes } from '@lexical/selection';
import { $isDecoratorBlockNode } from '@lexical/react/LexicalDecoratorBlockNode';
import {
  $getNearestNodeOfType,
  $getNearestBlockElementAncestorOrThrow,
  mergeRegister,
} from '@lexical/utils';
import { $isListNode, ListNode } from '@lexical/list';
import { createPortal } from 'react-dom';
import { $createHeadingNode, $isHeadingNode } from '@lexical/rich-text';
import styled from 'styled-components';

const DropdownWrapper = styled.div`
  z-index: 100;
  display: block;
  position: fixed;
  box-shadow:
    0 12px 28px 0 rgba(0, 0, 0, 0.2),
    0 2px 4px 0 rgba(0, 0, 0, 0.1),
    inset 0 0 0 1px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  min-width: 100px;
  min-height: 40px;
  background-color: #fff;

  .item {
    margin: 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 268px;

    .active {
      display: flex;
      width: 20px;
      height: 20px;
      background-size: contain;
    }

    &:first-child {
      margin-top: 8px;
    }

    &:last-child {
      margin-bottom: 8px;
    }

    &:hover {
      background-color: var(--button-background-color-hover, #ffedd1);
    }

    .text {
      display: flex;
      line-height: 20px;
      flex-grow: 1;
      width: 200px;
    }

    .icon {
      display: flex;
      width: 20px;
      height: 20px;
      user-select: none;
      margin-right: 12px;
      line-height: 16px;
      background-size: contain;
      margin-top: 1.5px;
    }
  }
`;

const LowPriority = 1;

const supportedBlockTypes = new Set(['paragraph', 'h1', 'h2', 'h3']);

type BlockType = 'h1' | 'h2' | 'h3' | 'paragraph';

const blockTypeToBlockName: Record<BlockType, string> = {
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  paragraph: 'Normal',
};

const Divider = () => {
  return <div className="divider" />;
};

interface BlockOptionsDropdownListProps {
  editor: LexicalEditor;
  blockType: string;
  toolbarRef: RefObject<HTMLDivElement>;
  showBlockOptionsDropDown: boolean;
  setShowBlockOptionsDropDown: (show: boolean) => void;
}

const BlockOptionsDropdownList = ({
  editor,
  blockType,
  toolbarRef,
  showBlockOptionsDropDown,
  setShowBlockOptionsDropDown,
}: BlockOptionsDropdownListProps) => {
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const toolbar = toolbarRef.current;
    const dropDown = dropDownRef.current;

    if (toolbar !== null && dropDown !== null) {
      const { top, left } = toolbar.getBoundingClientRect();
      dropDown.style.top = `${top + 40}px`;
      dropDown.style.left = `${left}px`;
    }
  }, [dropDownRef, toolbarRef]);

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event: MouseEvent) => {
        const { target } = event;

        if (
          !dropDown.contains(target as Node) &&
          !toolbar.contains(target as Node)
        ) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener('click', handle);

      return () => {
        document.removeEventListener('click', handle);
      };
    }

    return undefined;
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  useEffect(() => {
    const handleButtonPositionUpdate = () => {
      if (showBlockOptionsDropDown) {
        const toolbar = toolbarRef.current;
        const dropDown = dropDownRef.current;
        if (toolbar !== null && dropDown !== null) {
          const { top } = toolbar.getBoundingClientRect();
          const newPosition = top + 40;
          if (newPosition !== dropDown.getBoundingClientRect().top) {
            dropDown.style.top = `${newPosition}px`;
          }
        }
      }
    };

    document.addEventListener('scroll', handleButtonPositionUpdate);

    return () => {
      document.removeEventListener('scroll', handleButtonPositionUpdate);
    };
  }, [showBlockOptionsDropDown, toolbarRef]);

  const formatBlock = (type: BlockType) => {
    if (blockType !== type) {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          if (type === 'paragraph') {
            $wrapNodes(selection, () => $createParagraphNode());
          } else {
            $wrapNodes(selection, () => $createHeadingNode(type));
          }
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <DropdownWrapper ref={dropDownRef}>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('paragraph')}
        data-testid="rich-text-editor-paragraph-button"
      >
        <span className="icon ri-text" />
        <span className="text">Normal</span>
        {blockType === 'paragraph' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h1')}
        data-testid="rich-text-editor-h1-button"
      >
        <span className="icon ri-h-1" />
        <span className="text">Heading 1</span>
        {blockType === 'h1' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h2')}
        data-testid="rich-text-editor-h2-button"
      >
        <span className="icon ri-h-2" />
        <span className="text">Heading 2</span>
        {blockType === 'h2' && <span className="active" />}
      </button>
      <button
        type="button"
        className="item"
        onClick={() => formatBlock('h3')}
        data-testid="rich-text-editor-h3-button"
      >
        <span className="icon ri-h-3" />
        <span className="text">Heading 3</span>
        {blockType === 'h3' && <span className="active" />}
      </button>
    </DropdownWrapper>
  );
};

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState('paragraph');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedElementKey, setSelectedElementKey] = useState<string | null>(
    null
  );
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] =
    useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const { anchor } = selection;
        const { focus } = selection;
        const nodes = selection.getNodes();
        const extractedNodes = selection.extract();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }
            /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0];
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode;
            }

            if (textNode.__style !== '') {
              textNode.setStyle('');
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
            }
            // eslint-disable-next-line no-param-reassign
            node = textNode;
          } else if ($isHeadingNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat('');
          }
        });
      }
    });
  }, [editor]);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const blockTypeToIconClass: { [key: string]: string } = {
    paragraph: 'ri-text',
    h1: 'ri-h-1',
    h2: 'ri-h-2',
    h3: 'ri-h-3',
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      <button
        type="button"
        disabled={!canUndo}
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND, undefined);
        }}
        aria-label="Undo"
        className="toolbar-item spaced"
        data-testid="rich-text-editor-undo-button"
      >
        <i className="format ri-arrow-go-back-line" />
      </button>
      <button
        type="button"
        disabled={!canRedo}
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND, undefined);
        }}
        aria-label="Redo"
        className="toolbar-item"
        data-testid="rich-text-editor-redo-button"
      >
        <i className="format ri-arrow-go-forward-line" />
      </button>
      <Divider />
      {supportedBlockTypes.has(blockType) && (
        <>
          <button
            type="button"
            className="toolbar-item block-controls"
            onClick={() =>
              setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
            }
            aria-label="Formatting Options"
            data-testid="rich-text-editor-block-controls"
          >
            <span
              className={`icon block-type ${blockTypeToIconClass[blockType]}`}
            />
            <span className="text">
              {blockTypeToBlockName[blockType as BlockType]}
            </span>
            <i className="ri-arrow-drop-down-line" />
          </button>
          {showBlockOptionsDropDown &&
            createPortal(
              <BlockOptionsDropdownList
                editor={editor}
                blockType={blockType}
                toolbarRef={toolbarRef}
                showBlockOptionsDropDown={showBlockOptionsDropDown}
                setShowBlockOptionsDropDown={setShowBlockOptionsDropDown}
              />,
              document.body
            )}
          <Divider />
        </>
      )}
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
        aria-label="Format Bold"
        className={`toolbar-item spaced ${isBold ? 'active' : ''}`}
        data-testid="rich-text-editor-bold-button"
      >
        <i className="format ri-bold" />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
        className={`toolbar-item spaced ${isItalic ? 'active' : ''}`}
        aria-label="Format Italics"
        data-testid="rich-text-editor-italic-button"
      >
        <i className="format ri-italic" />
      </button>
      <button
        type="button"
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
        className={`toolbar-item spaced ${isUnderline ? 'active' : ''}`}
        aria-label="Format Underline"
        data-testid="rich-text-editor-underline-button"
      >
        <i className="format ri-underline" />
      </button>
      <button
        type="button"
        onClick={() => clearFormatting()}
        className="toolbar-item spaced"
        data-testid="rich-text-editor-clear-formatting-button"
        aria-label="Clear Formatting"
      >
        <i className="format clear-formatting ri-format-clear" />
      </button>
    </div>
  );
};

export default Toolbar;
